import { useEffect, useRef, useState } from 'react';

const now = () => (performance || Date).now();

const useOperationsTimeEstimation = (minOperationsForEstimation = 3) => {
    const [timeEstimation, setTimeEstimation] = useState<number | null>(null);
    const isEstimatingTime = useRef(false);
    const lastAdvanceTime = useRef<number | null>(null);
    const durations = useRef<number[]>([]);
    const operations = useRef<number>(0);
    const interval = useRef<number | null>(null);
    const intervalId = interval.current;

    useEffect(() => {
        return () => {
            if (intervalId) {
                window.clearInterval(intervalId);
            }
        };
    }, []);

    const startEstimation = (_operations: number) => {
        operations.current = _operations;
        lastAdvanceTime.current = now();
        if (isEstimatingTime.current) {
            console.warn('Attempted to start time estimation while it was already running.');
            if (interval.current) {
                window.clearInterval(interval.current);
                interval.current = null;
            }
        }
        isEstimatingTime.current = true;
    };

    const advanceEstimation = () => {
        if (operations.current === 0 || lastAdvanceTime.current === null) {
            console.warn('Attempted to advance time estimation before starting it.');
            return;
        }
        const currentTime = now();
        const elapsedTime = currentTime - lastAdvanceTime.current;

        lastAdvanceTime.current = currentTime;
        durations.current.push(elapsedTime);

        if (durations.current.length < minOperationsForEstimation) {
            return;
        }
        if (!interval.current) {
            interval.current = window.setInterval(() => {
                setTimeEstimation(timeEstimation => (!timeEstimation ? null : Math.max(0, timeEstimation - 1000)));
            }, 1000);
        }

        removeOperations(1);
    };

    const updateOperations = (newOperations: number) => {
        const average = durations.current.reduce((acc, duration) => acc + duration, 0) / durations.current.length;
        const remainingOperations = newOperations;
        const remainingTime = average * remainingOperations;
        setTimeEstimation(remainingTime);
        operations.current = remainingOperations;
    };

    const addOperations = (amount: number) => {
        updateOperations(operations.current + amount);
    };

    const removeOperations = (amount: number) => {
        updateOperations(operations.current - amount);
    };

    const completeEstimation = () => {
        setTimeEstimation(null);
        lastAdvanceTime.current = null;
        durations.current = [];
        operations.current = 0;
        if (interval.current) {
            window.clearInterval(interval.current);
            interval.current = null;
        }
        isEstimatingTime.current = false;
    };

    return {
        timeEstimation,
        isEstimatingTime,
        startEstimation,
        advanceEstimation,
        completeEstimation,
        addOperations,
        removeOperations,
    };
};

export default useOperationsTimeEstimation;
