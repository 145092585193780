import { CheckCircleIcon } from '@heroicons/react/solid';
import { Alert, Button, Collapse, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { Color } from '../../Color';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';
import UserContext, { SignUpResult, SignUpStatus, TUser } from '../../contexts/UserContext';
import { validateEmail, validateName, validateUsername } from '../../util/string';
import AuthTextField from './TextField';
import usePasswordValidation from 'src/hooks/usePasswordValidator';

interface Props {
    onComplete: (status: SignUpResult) => void;
    onCancel: () => void;
}

function PasswordValidationListItem({ content, isChecked }: { content: string; isChecked: boolean }) {
    const transition = 'color 0.2s';
    return (
        <ListItem disableGutters disablePadding>
            <ListItemIcon sx={{ minWidth: '40px' }}>
                <CheckCircleIcon width={24} color={isChecked ? '#67cd67' : '#525157'} style={{ transition }} />
            </ListItemIcon>
            <ListItemText
                primaryTypographyProps={{ color: isChecked ? Color.White : '#515257', style: { transition } }}
                primary={content}
            />
        </ListItem>
    );
}

const MIN_PASSWORD_LENGTH = 8;

export default function SignUp({ onCancel, onComplete }: Props) {
    const { setShowLoading } = useContext(FeedbackContext) as TFeedback;
    const { signUpWithEmailAndPassword } = useContext(UserContext) as TUser;
    const [errorMessage, setErrorMessage] = useState<string>();

    const [fullName, setFullName] = useState('');
    const [userName, setUserName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const errorRef = useRef<HTMLDivElement>(null);
    const passwordValidState = usePasswordValidation(password);

    const scrollToError = () => {
        errorRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        if (errorMessage) {
            scrollToError();
        }
    }, [errorMessage]);

    const signUp = async () => {
        try {
            if (!fullName) {
                setErrorMessage('Name is required.');
                return;
            }
            if (!validateName(fullName)) {
                setErrorMessage('Names cannot contain special characters.');
                return;
            }
            if (!email) {
                setErrorMessage('Email is required.');
                return;
            }
            if (!validateEmail(email)) {
                setErrorMessage('Please enter a valid email address.');
                return;
            }
            if (!userName) {
                setErrorMessage('Username is required.');
                return;
            }
            if (!validateUsername(userName)) {
                setErrorMessage(
                    'Username has to be between 5 to 15 characters long. Only letters, numbers, dashes, periods and underscores are allowed.',
                );
                return;
            }
            if (!password) {
                setErrorMessage('Password is required.');
                return;
            }
            const isPasswordValid = Object.values(passwordValidState).every(value => value);
            if (!isPasswordValid) {
                setErrorMessage('Password requirements are not fully met.');
                return;
            }
            if (password !== confirmPassword) {
                setErrorMessage('Passwords do not match.');
                return;
            }

            setShowLoading(true);

            const result = await signUpWithEmailAndPassword({
                email,
                password,
                fullName,
                userName,
            });
            console.log('signUpWithEmailAndPassword result', result);
            switch (result.status) {
                case SignUpStatus.Success:
                    onComplete(result);
                    break;
                case SignUpStatus.Error:
                    setErrorMessage(result.error?.message);
                    break;
            }
        } catch (error) {
            setErrorMessage(error as string);
        } finally {
            setShowLoading(false);
        }
    };

    return (
        <Stack
            direction="column"
            spacing={3}
            sx={{
                bgcolor: Color.PrimaryDarkGrayBlue,
                minWidth: { xs: '100%', sm: '300px' },
                justifyContent: 'center',
                height: 'fit-content',
                overflowY: 'auto',
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    signUp();
                }
            }}
        >
            <Typography
                sx={{
                    fontFamily: "'Nocturne Serif', serif",
                    // TODO: Color.ts
                    color: '#E6E6E6',
                    fontSize: '40px',
                    fontWeight: 600,
                    lineHeight: '56px',
                    letterSpacing: '-0.03em',
                    textAlign: 'center',
                }}
            >
                Sign Up
            </Typography>
            {errorMessage && (
                <Alert
                    severity="error"
                    sx={{ width: '300px' }}
                    ref={errorRef}
                    /*onClose={handleClose}*/
                >
                    {errorMessage}
                </Alert>
            )}

            <AuthTextField
                label="Name"
                value={fullName}
                onChange={value => {
                    setFullName(value);
                }}
            />
            <AuthTextField
                label="Email"
                value={email}
                onChange={value => {
                    setEmail(value);
                }}
            />
            <AuthTextField
                label="Username"
                value={userName}
                onChange={value => {
                    setUserName(value);
                }}
            />
            <AuthTextField
                label="Password"
                type="password"
                value={password}
                onChange={value => {
                    setPassword(value);
                }}
            />
            <Collapse timeout={600} in={!!password.length} style={{ marginTop: 0 }}>
                <List disablePadding sx={{ pt: 2 }}>
                    <PasswordValidationListItem
                        content={`Minimum ${MIN_PASSWORD_LENGTH} characters`}
                        isChecked={passwordValidState.length}
                    />
                    <PasswordValidationListItem
                        content="At least 1 lowercased letter"
                        isChecked={passwordValidState.lowerCaseLetter}
                    />
                    <PasswordValidationListItem
                        content="At least 1 uppercased letter"
                        isChecked={passwordValidState.upperCaseLetter}
                    />
                    <PasswordValidationListItem content="At least 1 number" isChecked={passwordValidState.number} />
                    <PasswordValidationListItem
                        content="At least 1 special character"
                        isChecked={passwordValidState.specialCharacter}
                    />
                </List>
            </Collapse>
            <AuthTextField
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={value => {
                    setConfirmPassword(value);
                }}
            />
            <Button
                // type="submit"
                variant="irdbGradient"
                sx={{
                    height: '56px',
                    textTransform: 'none',
                }}
                onClick={signUp}
            >
                Sign Up
            </Button>
            <Button
                variant="irdbText"
                onClick={() => onCancel()}
                sx={{
                    // Overrides stack margin
                    '&&': {
                        mt: 4,
                    },
                }}
            >
                Back
            </Button>
        </Stack>
    );
}
