import { Divider, Stack } from '@mui/material';
import { useContext } from 'react';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';
import { metaFieldForMetaType, metaIncludes, MetaField } from '../../contexts/MetaContext';
import { Image } from '../../contexts/MediaContext';
import { MetaType, MetaContent } from '../../types/MetaTypes';
import { CardTypeForm } from './CardType';
import { CustomForm, Custom } from './Custom';
import { Description, DescriptionForm } from './Description';
import { Link, LinkForm } from './Link';
import { ProductLink } from './ProductLink';
import { TagsForm, Tags } from './Tags';
import { Title, TitleForm } from './Title';
import PrivateIndicator from './PrivateIndicator';

export default function GeneralCardStack({
    image,
    meta,
    isEditing,
    editingTypes = [
        MetaType.CardType,
        MetaType.Title,
        MetaType.Link,
        MetaType.Description,
        MetaType.Tags,
        MetaType.Custom,
    ],
    onChange,
}: {
    image: Image;
    meta: MetaField[];
    isEditing: boolean;
    editingTypes?: MetaType[];
    onChange: (metaType: MetaType, metaContent: MetaContent) => void;
}) {
    const { confirm } = useContext(FeedbackContext) as TFeedback;

    if (isEditing) {
        return (
            <Stack direction="column" spacing={2}>
                {editingTypes.includes(MetaType.CardType) && (
                    <CardTypeForm
                        metaField={metaFieldForMetaType(meta, MetaType.CardType)}
                        onChange={async (metaContent: MetaContent) => {
                            if (
                                await confirm({
                                    title: 'Are your sure?',
                                    message: 'Some data may be lost',
                                    yes: 'Switch Templates',
                                    no: 'Cancel',
                                })
                            ) {
                                onChange(MetaType.CardType, metaContent);
                            }
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Title) && (
                    <TitleForm
                        metaField={metaFieldForMetaType(meta, MetaType.Title)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Title, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Link) && (
                    <LinkForm
                        metaField={metaFieldForMetaType(meta, MetaType.Link)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Link, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Description) && (
                    <DescriptionForm
                        metaField={metaFieldForMetaType(meta, MetaType.Description)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Description, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Tags) && (
                    <TagsForm
                        metaField={metaFieldForMetaType(meta, MetaType.Tags)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Tags, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Custom) && (
                    <CustomForm
                        metaField={metaFieldForMetaType(meta, MetaType.Custom)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Custom, metaContent);
                        }}
                    />
                )}
            </Stack>
        );
    } else {
        return (
            <Stack spacing={2} divider={<Divider />}>
                <Stack
                // In WebApp, the meta components have the spacing. Maybe fix.
                // spacing={2}
                >
                    <Title image={image} metaField={metaFieldForMetaType(meta, MetaType.Title)} />
                    <PrivateIndicator mt={4} image={image} />
                    <Link image={image} metaField={metaFieldForMetaType(meta, MetaType.Link)} />
                    <ProductLink image={image} metaField={metaFieldForMetaType(meta, MetaType.ProductLink)} />
                </Stack>
                {metaIncludes(meta, [MetaType.Description, MetaType.Tags, MetaType.Custom]) && (
                    <Stack
                    // In WebApp, the meta components have the spacing. Maybe fix.
                    // spacing={2}
                    >
                        <Description image={image} metaField={metaFieldForMetaType(meta, MetaType.Description)} />
                        <Tags image={image} metaField={metaFieldForMetaType(meta, MetaType.Tags)} />
                        <Custom image={image} metaField={metaFieldForMetaType(meta, MetaType.Custom)} />
                    </Stack>
                )}
            </Stack>
        );
    }
}
