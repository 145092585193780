import { useRef } from 'react';

type PromiseOrPromiseCallback = Promise<any> | (() => Promise<any>);

const usePromiseQueue = (onQueueFinished: () => void) => {
    const queue = useRef<Array<PromiseOrPromiseCallback>>([]);
    const isExecuting = useRef(false);

    const execute = async (promise: PromiseOrPromiseCallback) => {
        if ('then' in promise) {
            await promise;
        } else {
            await promise();
        }
        if (queue.current.length) {
            await execute(queue.current.shift()!);
            return;
        }
        isExecuting.current = false;
        onQueueFinished();
    };

    /** Pass a function to execute in a sequence, or a promise to execute concurrently. */
    const addToQueue = (promise: PromiseOrPromiseCallback) => {
        if (isExecuting.current) {
            queue.current.push(promise);
            return;
        }
        isExecuting.current = true;
        return execute(promise);
    };

    const clearQueue = () => {
        queue.current = [];
    };

    return { addToQueue, clearQueue, isExecuting };
};

export default usePromiseQueue;
