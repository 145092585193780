import { TLink } from './Link';
import { TProductLink } from './ProductLink';

// See ImageMeta.swift
export enum MetaType {
    ArtCard = 'ArtCard', // You probably want CardType
    ArtistName = 'ArtistName',
    CardType = 'CardType', // must always be present from API call
    Category = 'Category',
    ContactInfo = 'ContactInfo', // does not appear to be used anymore
    Custom = 'Custom',
    Description = 'Description',
    DisplayLocation = 'DisplayLocation',
    Email = 'Email',
    GalleryName = 'GalleryName',
    ImageInfo = 'ImageInfo', // this is probably replaced by Description enum
    Link = 'Link',
    Medium = 'Medium',
    NftLink = 'NftLink',
    Phone = 'Phone',
    Price = 'Price',
    ProductLink = 'ProductLink',
    Provenance = 'Provenance',
    Size = 'Size',
    SocialLinks = 'SocialLinks',
    Title = 'Title',
    Tags = 'Tags',
    Unknown = 'Unknown',
    Urls = 'Urls', // I am pretty sure this is replaced by Link enum
    UserProvidedInfo = 'UserProvidedInfo', // to be replaced by Custom enum
    Year = 'Year',
}

export type TArtistName = {
    name: string;
};

export enum CardType {
    ArtCard = 'artCard',
    General = 'general',
}

export type TCardType = {
    cardType: CardType;
};

export enum Category {
    Billboard = 'billboard',
    BusBench = 'busBench',
    BusSide = 'busSide',
    Clothing = 'clothing',
    PaperAd = 'paperAd',
    ProductPackaging = 'productPackaging',
    Unknown = 'unknown',
}

export type TCategory = {
    category: Category;
};

export type TContactInfo = {
    email: string;
    phone: string;
};

// TODO: Type this
// custom: {
//     "Custom": "Field",
//     "Color": "Red "
// }
// export type TCustom = {
//     custom: any
// };
export type TCustom = any;

export type TDescription = {
    description: string;
};

export type TDisplayLocation = {
    displayLocation: string;
};

export type TEmail = {
    email: string;
};

export type TGalleryName = {
    galleryName: string;
};

export type TImageInfo = {
    info: string;
};

export type TLocation = {
    location: string;
};

export type TMedium = {
    medium: string;
};

export type TNftLink = {
    nft: string;
    // tokenId: string;
    // contractId: string;
};

export type TPhone = {
    phone: string;
};

export type TPrice = {
    price: string;
};

export type TProvenance = {
    provenance: string;
};

export type TSize = {
    size: string;
};

export type TSocialLinks = {
    socials: string;
    // links: {
    //     service: string;
    //     // icon: string;
    //     url: string;
    // }[];
};

export type TTags = {
    tags: string[];
};

export type TTitle = {
    title: string;
};

export type TYear = {
    year: string;
};

interface MetaContentTypeMap {
    [MetaType.ArtCard]: { cardType: CardType.ArtCard };
    [MetaType.ArtistName]: TArtistName;
    [MetaType.CardType]: TCardType;
    [MetaType.Category]: TCategory;
    [MetaType.ContactInfo]: TContactInfo;
    [MetaType.Custom]: TCustom;
    [MetaType.Description]: TDescription;
    [MetaType.DisplayLocation]: TDisplayLocation;
    [MetaType.Email]: TEmail;
    [MetaType.GalleryName]: TGalleryName;
    [MetaType.ImageInfo]: TImageInfo;
    [MetaType.Link]: TLink;
    [MetaType.Medium]: TMedium;
    [MetaType.NftLink]: TNftLink;
    [MetaType.Phone]: TPhone;
    [MetaType.Price]: TPrice;
    [MetaType.ProductLink]: TProductLink;
    [MetaType.Provenance]: TProvenance;
    [MetaType.Size]: TSize;
    [MetaType.SocialLinks]: TSocialLinks;
    [MetaType.Tags]: TTags;
    [MetaType.Title]: TTitle;
    [MetaType.Year]: TYear;
}

export type MetaContentMap = {
    [key in MetaType]: key extends keyof MetaContentTypeMap ? MetaContentTypeMap[key] : any;
};

export type MetaContent<T extends MetaType = MetaType> = MetaContentMap[T];
