import { useContext } from 'react';
import { Card, Stack, Typography, Box } from '@mui/material';
import { ImageOperation, Query } from '../../../contexts/MediaContext';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { Color } from '../../../Color';
import { IconButton } from '../../general/IconButton';
import FeedbackContext, { TFeedback } from '../../../contexts/FeedbackContext';
import { useCopyToClipboard } from 'usehooks-ts';

interface Props {
    imageOperations: ImageOperation<Query>[];
}

export default function Unavailable({ imageOperations }: Props) {
    const [, copyToClipboard] = useCopyToClipboard();
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { setSuccessMessage, setErrorMessage } = useContext(FeedbackContext) as TFeedback;
    if (imageOperations.length === 0) {
        return null;
    } else {
        return (
            <Card
                sx={{
                    p: 2,
                    borderRadius: 2,
                    backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.White,
                }}
                elevation={0}
            >
                <Stack direction="column" spacing={2}>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            alignItems: 'center',
                        }}
                    >
                        <i
                            className="fa-solid fa-magnifying-glass fa-2xl"
                            style={{ marginRight: '16px', color: '#ED1B7F' }}
                        ></i>
                        <Typography
                            sx={{
                                fontFamily: 'Nocturne Serif',
                                fontSize: '24px',
                                fontWeight: 400,
                                lineHeight: '26px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                            }}
                        >
                            {imageOperations.length > 1 ? `${imageOperations.length} IRCODES Found` : 'IRCODE Found'}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Nunito Sans',
                                fontSize: '20px',
                                fontWeight: 400,
                                lineHeight: '28px',
                                letterSpacing: '0.01em',
                                textAlign: 'left',
                            }}
                        >
                            {imageOperations.length > 1 ? 'These IRCODES are' : 'This IRCODE is'} already registered
                        </Typography>
                    </Stack>
                    {imageOperations.map(imageOperation => {
                        // console.log('unavailableIrcode', unavailableIrcode);
                        const imgSrc = imageOperation.cropped?.preview ?? imageOperation.original?.preview;
                        const url = `${window.location.origin}/dashboard/ircodes/${imageOperation.operation.Results?.Image?.imageID}`;

                        return (
                            <Stack
                                key={imageOperation.id}
                                direction="row"
                                sx={{
                                    flexGrow: 1,
                                    alignItems: 'center',
                                    borderRadius: 2,
                                    backgroundColor:
                                        darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                                }}
                                onClick={() => {
                                    window.open(url, '_blank');
                                }}
                            >
                                <Box
                                    component="img"
                                    sx={{
                                        flexShrink: 0,
                                        width: 140,
                                        height: 100,
                                        borderTopLeftRadius: { xs: '8px', sm: '8px' },
                                        borderTopRightRadius: { xs: '8px', sm: 0 },
                                        borderBottomLeftRadius: { xs: 0, sm: '8px' },
                                        borderBottomRightRadius: { xs: 0, sm: 0 },
                                        objectFit: 'contain',
                                        overflow: 'hidden',
                                    }}
                                    src={imgSrc}
                                    alt=""
                                />
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{
                                        flexGrow: 1,
                                        p: 2,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            flexGrow: 1,
                                            textAlign: 'left',

                                            fontFamily: 'Nunito Sans',
                                            fontSize: '20px',
                                            fontWeight: 400,
                                            lineHeight: '28px',
                                            letterSpacing: '0.01em',

                                            textTransform: 'uppercase',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {imageOperation.operation.Results?.Image?.metaContent?.title ?? 'Untitled'}{' '}
                                        <i className="fa-solid fa-arrow-right" style={{ marginLeft: 4 }}></i>
                                    </Typography>
                                    {imageOperation.operation.Results?.ImagePrivate ?
                                        'Private'
                                    :   <IconButton
                                            icon="fa-link"
                                            tooltip="Copy to Clipboard"
                                            onClick={event => {
                                                event.stopPropagation();
                                                copyToClipboard(url)
                                                    .then(() => {
                                                        setSuccessMessage('Copied to clipboard');
                                                    })
                                                    .catch(() => {
                                                        setErrorMessage('Failed to copy to clipboard');
                                                    });
                                            }}
                                        />
                                    }
                                </Stack>
                            </Stack>
                        );
                    })}
                </Stack>
            </Card>
        );
    }
}
