import React, { useContext, useState } from 'react';
import { Dialog, Box, Typography, TextField, Button } from '@mui/material';
import AdminContext, { Ircode, TAdmin, UserSearchResults } from '../../contexts/AdminContext';
import useAuth from '../../hooks/useAuth';
import { useDebounce } from '@uidotdev/usehooks';
import { Color } from 'src/Color';

interface PushNotificationFormProps {
    open: boolean;
    onClose: () => void;
    sendPushNotification: (
        userId: number[],
        message: string,
        title: string,
        imageID: string,
        twoFACode: string,
    ) => Promise<void>;
    resetSelectedUsers: () => void;
    selectedUsers: UserSearchResults[];
}

const PushNotificationForm: React.FC<PushNotificationFormProps> = ({
    open,
    onClose,
    sendPushNotification,
    selectedUsers,
    resetSelectedUsers,
}) => {
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [imageID, setImageID] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [searchTerms, setSearchTerms] = useState('');
    const debouncedSearchTerms = useDebounce(searchTerms, 500);
    const [searchResults, setSearchResults] = useState<Ircode[]>([]);

    const { requestAdminTwoFactorAuth, fetchIrcode } = useContext(AdminContext) as TAdmin;

    useAuth(() => {
        const fetchSearchResults = async () => {
            if (!debouncedSearchTerms.trim()) {
                setSearchResults([]);
                return;
            }
            try {
                const results = await fetchIrcode('registered', debouncedSearchTerms);
                if (results && results.Results && results.Results.Ircodes) {
                    const sortedResults = results.Results.Ircodes.sort((a, b) => {
                        const startsWithA = a.title.toLowerCase().startsWith(debouncedSearchTerms.toLowerCase());
                        const startsWithB = b.title.toLowerCase().startsWith(debouncedSearchTerms.toLowerCase());
                        if (startsWithA && !startsWithB) {
                            return -1;
                        } else if (!startsWithA && startsWithB) {
                            return 1;
                        } else {
                            return a.title.localeCompare(b.title);
                        }
                    });
                    setSearchResults(sortedResults);
                } else {
                    setSearchResults([]);
                }
            } catch (error) {
                console.error('Search error:', error);
                setSearchResults([]);
            }
        };

        fetchSearchResults();
    }, [debouncedSearchTerms]);

    const handleSend = async () => {
        const userIds = selectedUsers.map(user => user.userID);

        console.log('Requesting a new 2FA code...');
        const newCode = await requestAdminTwoFactorAuth('2FA Verification', phoneNumber, 'Enter the 2FA code:');
        if (!newCode) {
            console.error('No 2FA code received, please request a new code.');
            return;
        }

        console.log('Sending push notification', userIds, message, title, imageID, newCode);
        await sendPushNotification(userIds, message, title, imageID, newCode);
        setTitle('');
        setMessage('');
        setImageID('');
        setPhoneNumber('');
        setSearchTerms('');
        setSearchResults([]);
        resetSelectedUsers();
        onClose();
    };

    const handleSelectIrcode = (setIrcode: Ircode) => {
        setImageID(setIrcode.imageID);
        setTitle(setIrcode.title);
        setSearchTerms(setIrcode.imageID);
        setSearchResults([]);
    };

    return (
        <Dialog open={open} onClose={onClose} sx={{ '.MuiPaper-elevation': { overflow: 'visible' } }}>
            <Box p={2}>
                <Typography variant="h6">Send Push Notification</Typography>
                <Typography variant="subtitle1">To: {selectedUsers.map(user => user.userName).join(', ')}</Typography>
                <TextField
                    label="Search for IRCODE to attach to push notification"
                    value={searchTerms}
                    onChange={e => setSearchTerms(e.target.value)}
                    fullWidth
                    margin="normal"
                />

                {searchResults.length > 0 && (
                    <Box
                        sx={{
                            position: 'absolute',
                            height: '20em',
                            width: '30em',
                            overflowY: 'auto',
                            zIndex: 2,
                            mt: -1,
                        }}
                    >
                        {searchResults.map(ircode => (
                            <Box
                                key={ircode.imageID}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    p: 1,
                                    backgroundColor: Color.Black,
                                    '&:hover': { backgroundColor: '#111' },
                                }}
                                onClick={() => handleSelectIrcode(ircode)}
                            >
                                {ircode.imageUrl ?
                                    <img
                                        src={ircode.imageUrl}
                                        alt={ircode.title}
                                        style={{ width: 50, height: 50, objectFit: 'cover', flexShrink: 0 }}
                                    />
                                :   <Box sx={{ width: 50, height: 50, backgroundColor: '#333', flexShrink: 0 }} />}
                                <Typography sx={{ color: Color.White, ml: 1 }}>{ircode.title || 'Untitled'}</Typography>
                            </Box>
                        ))}
                    </Box>
                )}
                <TextField
                    label="Title"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Message"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    fullWidth
                    margin="normal"
                />

                <Button color="primary" variant="contained" onClick={handleSend}>
                    Send Notification
                </Button>
                <Button onClick={onClose}>Cancel</Button>
            </Box>
        </Dialog>
    );
};

export default PushNotificationForm;
