import { ReactNode } from 'react';
import { Timespan } from '../../../util/timespan';
import { Color } from '../../../Color';
import { Box, Button, Card, LinearProgress, Stack, Typography } from '@mui/material';

export interface UploaderProgressBarProps {
    label: ReactNode;
    darkMode: boolean;
    progress: number | null;
    isEstimatingTime?: boolean;
    remainingTime?: Timespan | null;
    onCancel?: () => void;
    cancelDisabled?: boolean;
}

const getTimespanString = (timespan: Timespan) => {
    let result = '';
    if (timespan.days > 0) {
        result += `${timespan.days}d `;
    }
    if (timespan.hours > 0) {
        result += `${timespan.hours}h `;
    }
    if (timespan.minutes > 0) {
        result += `${timespan.minutes}m `;
    }
    if (timespan.seconds > 0) {
        result += `${timespan.seconds}s `;
    } else {
        result += '1s ';
    }
    return result.trim();
};

const UploaderProgressBar = ({
    label,
    darkMode,
    progress,
    onCancel,
    isEstimatingTime,
    remainingTime,
    cancelDisabled,
}: UploaderProgressBarProps) => (
    <Card
        sx={{
            p: 2,
            borderRadius: 2,
            backgroundColor: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
        }}
        elevation={0}
    >
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Stack sx={{ width: '12em' }} direction="column">
                <Typography
                    sx={{
                        fontFamily: 'Nunito Sans',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '19px',
                        letterSpacing: '0.15em',
                        textAlign: 'left',
                        color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        textTransform: 'uppercase',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {label}
                </Typography>
                {(isEstimatingTime || !!remainingTime) && (
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '13px',
                            fontWeight: 400,
                            lineHeight: '19px',
                            letterSpacing: '0.15em',
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                            mt: 0.5,
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {isEstimatingTime ?
                            'Estimating time...'
                        :   'About ' + getTimespanString(remainingTime!) + ' left...'}
                    </Typography>
                )}
            </Stack>
            <LinearProgress
                {...(progress !== null ? { variant: 'determinate', value: progress } : { variant: 'indeterminate' })}
                sx={{
                    width: '100%',
                    height: '4px',
                    borderRadius: '2px',
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: Color.Purple,
                    },
                }}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    backgroundColor: 'red',
                }}
            />
            {onCancel && (
                <Button disabled={cancelDisabled} variant="irdbText" onClick={onCancel}>
                    Cancel
                    <i className="fa-light fa-x" style={{ marginLeft: 4 }} />
                </Button>
            )}
        </Stack>
    </Card>
);

export default UploaderProgressBar;
