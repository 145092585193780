import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    InputAdornment,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import MediaContext, { Image, PagedResults, TMedia, replaceImage } from '../../../contexts/MediaContext';
import UserContext, { TUser } from '../../../contexts/UserContext';
import { Color } from '../../../Color';
import { IconButton } from '../../general/IconButton';
import OwnedIrcodeRow from './OwnedIrcodeRow';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import Pagination from '../../general/Pagination';
import { Select } from '../../general/Select';
import { useDebounce } from '@uidotdev/usehooks';
import MetaContext, { TMeta, indexForMetaType, newMetaField, validateMeta } from '../../../contexts/MetaContext';
import FeedbackContext, { TFeedback } from '../../../contexts/FeedbackContext';
import { useSearchParams } from 'react-router-dom';
import { MetaContent, MetaType } from '../../../types/MetaTypes';
import { ILink } from '../../../types/Link';
import { IProductLink } from '../../../types/ProductLink';
import { addOrRemove } from '../../../util/array';
import { camelCaseToWords } from '../../../util/string';
import { Campaign } from 'src/contexts/EnterpriseContext';
import { ImageSortByField, ImageSortOrderField } from '../../../types/Image';
import useMetaErrors from '../../../hooks/metaErrors/useMetaErrors';
import { copyImageMetaData } from '../../../util/imageOperations/copyMetaData';

// TODO: Move to MetaTypes.ts
const names = [
    'CardType',
    'Title',
    'ArtistName',
    'Link',
    'ProductLink',
    'GalleryName',
    'Year',
    'Size',
    'Medium',
    'Provenance',
    'Price',
    'Description',
    'Tags',
    'DisplayLocation',
    'Email',
    'Phone',
    'Custom',
];
// TODO: ...Move to hook

const includesAny = (value: string, ...args: string[]) => args.findIndex(arg => value.includes(arg)) !== -1;

const validateSearchTerm = (searchTerm: string) =>
    !searchTerm?.length || (searchTerm !== '.' && !includesAny(searchTerm, '\\', '/', '#', '%'));

enum BulkOperation {
    ApplyToCampaign = 'applyToCampaign',
    RemoveFromCampaign = 'removeFromCampaign',
    Delete = 'delete',
    Draft = 'draft',
    Edit = 'edit',
    Nothing = 'nothing',
    Publish = 'publish',
}

interface Props {
    // Is there an operation that should block display?
    isProcessing: boolean;
    fetchImages: (
        page: number,
        limit: number,
        sortBy: ImageSortByField,
        sortOrder: ImageSortOrderField,
        hideCampaigns: boolean,
    ) => Promise<PagedResults<Image[]>>;
    fetchCampaigns?: () => Promise<PagedResults<Campaign[]>>;
    addImageToCampaign?: (campaignID: number, imageID: string) => Promise<any>;
    removeImageFromCampaign?: (imageID: string) => Promise<boolean>;
    search?: (
        searchTerm: string,
        owner: boolean,
        page: number,
        limit: number,
        sortBy: ImageSortByField,
        sortOrder: ImageSortOrderField,
    ) => Promise<PagedResults<Image[]>>;
    onEdit: (imageID: string) => void;
    remove: (imageID: string) => Promise<boolean>;
}

export default function OwnedIrcodes({
    isProcessing,
    fetchImages,
    fetchCampaigns,
    addImageToCampaign,
    removeImageFromCampaign,
    search,
    onEdit,
    remove,
}: Props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const { darkMode, isMobile } = useContext(ThemeContext) as TTheme;
    const { confirm, setShowLoading, notify } = useContext(FeedbackContext) as TFeedback;
    const { user } = useContext(UserContext) as TUser;
    const { load, status } = useContext(MediaContext) as TMedia;
    const { save: saveMeta } = useContext(MetaContext) as TMeta;
    const setMetaErrors = useMetaErrors.use.setErrors();
    const hasMetaErrors = useMetaErrors.use.hasErrors().length > 0;

    // TODO: we should accept this from the parent ... probably
    let paramPage = parseInt(searchParams.get('page') ?? '1');
    let paramLimit = parseInt(searchParams.get('limit') ?? '25');
    const paramSortBy: ImageSortByField = searchParams.get('sort') === 'title' ? 'title' : 'internalID';
    const paramSortOrder: ImageSortOrderField = searchParams.get('order') === 'asc' ? 'ASC' : 'DESC';

    // prevent URL tampering with limit
    if (![25, 50, 100, 500].includes(paramLimit)) {
        paramPage = 1;
        paramLimit = 25;
    }

    const [page, setPage] = useState(paramPage);
    const [limit, setLimit] = useState(paramLimit);
    const [sortBy, setSortBy] = useState(paramSortBy);
    const [sortOrder, setSortOrder] = useState(paramSortOrder);
    const [hideCampaigns, setHideCampaigns] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [images, setImages] = useState<PagedResults<Image[]>>();

    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 250);
    const [searchPageCount, setSearchPageCount] = useState(0);
    const [searchImages, setSearchImages] = useState<PagedResults<Image[]>>();
    const [isValidSearchTerm, setIsValidSearchTerm] = useState(true);
    const [selectedImageIds, setSelectedImageIds] = useState<string[]>([]);

    const refreshImages = useCallback(() => {
        if (searchTerm) return;
        setIsLocalLoading(true);
        fetchImages(page - 1, limit, sortBy, sortOrder, hideCampaigns)
            .then((results: PagedResults<Image[]>) => {
                setImages(results);
                setPageCount(Number(results.Pages));
            })
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setIsLocalLoading(false);
            });
    }, [fetchImages, limit, page, sortBy, sortOrder, hideCampaigns, searchTerm]);

    useEffect(() => {
        refreshImages();
    }, [user?.userID, refreshImages]);

    const refreshSearchResults = useCallback(() => {
        const isValidSearchTerm = validateSearchTerm(debouncedSearchTerm);
        setIsValidSearchTerm(isValidSearchTerm);
        if (debouncedSearchTerm === '' || !isValidSearchTerm) {
            setSearchImages(undefined);
            return;
        }

        const debouncedSearch = async () => {
            if (!debouncedSearchTerm || search === undefined) {
                return;
            }
            setIsLocalLoading(true);

            try {
                const results = await search(debouncedSearchTerm, true, page - 1, limit, sortBy, sortOrder);
                setSearchImages(results);
                setSearchPageCount(results.Pages);
            } catch (error) {
                console.error('Error while searching:', error);
            } finally {
                setIsLocalLoading(false);
            }
        };

        debouncedSearch();
    }, [debouncedSearchTerm, search, page, limit, sortBy, sortOrder]);

    useEffect(() => {
        refreshSearchResults();
    }, [page, limit, sortBy, sortOrder, refreshSearchResults]);

    useEffect(() => {
        if (searchImages?.Results.length) {
            setSelectedImageIds(prevSelectedImageIds => {
                return prevSelectedImageIds.filter(imageID =>
                    searchImages.Results.find(image => image.imageID === imageID),
                );
            });
        }
    }, [searchImages]);

    const isSearching = searchTerm !== '';

    const replaceScopeImage = (image: Image) => {
        const set = isSearching ? setSearchImages : setImages;
        set(pagedResults => ({
            ...pagedResults!,
            Results: replaceImage(image, pagedResults?.Results ?? []),
        }));
    };

    const copyOnConfirm = async (metaType: MetaType, metaContent: MetaContent) => {
        if (
            await confirm({
                title: 'Confirm Copy',
                message: 'Copy this field to other IRCODES?',
                yes: 'Yes',
                no: 'No',
            })
        ) {
            for (const imageID of selectedImageIds) {
                const image = structuredClone(scopeImages.find(i => i.imageID === imageID)!);
                if (image === undefined) {
                    // TODO: If you bulk delete some images prior this can happen
                    console.error('Could not find image with ID', imageID);
                    continue;
                }

                const index = indexForMetaType(image.metaArray, metaType);
                if (index !== -1) {
                    switch (metaType) {
                        case MetaType.Custom:
                            image.metaArray[index].metaContent = {
                                custom: {
                                    ...image.metaArray[index].metaContent.custom,
                                    ...metaContent.custom,
                                },
                            };
                            break;
                        case MetaType.Tags:
                            image.metaArray[index].metaContent = {
                                tags: Array.from(
                                    new Set([...image.metaArray[index].metaContent.tags, ...metaContent.tags]),
                                ),
                            };
                            break;
                        case MetaType.Link:
                        case MetaType.ProductLink: {
                            let linkIndex;
                            if (metaType === MetaType.ProductLink) {
                                // Remove blank ProductLinks
                                image.metaArray[index].metaContent.links = image.metaArray[
                                    index
                                ].metaContent.links.filter(
                                    (link: IProductLink) =>
                                        link.title || link.linkToFollow || link.imageUrl || !link.upload?.preview,
                                );
                                // Check for the copied ProductLink
                                linkIndex = image.metaArray[index].metaContent.links.findIndex(
                                    (link: IProductLink) => link.linkToFollow === metaContent.links[0].linkToFollow,
                                );
                            } else {
                                // Remove blank Links
                                image.metaArray[index].metaContent.links = image.metaArray[
                                    index
                                ].metaContent.links.filter((link: ILink) => link.title || link.url);
                                // Check for the copied Link
                                linkIndex = image.metaArray[index].metaContent.links.findIndex(
                                    (link: ILink) => link.url === metaContent.links[0].url,
                                );
                            }

                            if (linkIndex !== -1) {
                                // Link with this URL exists in the view, update its properties
                                image.metaArray[index].metaContent.links[linkIndex] = metaContent.links[0];
                            } else {
                                image.metaArray[index].metaContent.links = [
                                    ...image.metaArray[index].metaContent.links,
                                    ...metaContent.links,
                                ];
                            }
                            break;
                        }
                        default:
                            image.metaArray[index].metaContent = metaContent;
                            break;
                    }
                } else {
                    image.metaArray.push(newMetaField(metaType, metaContent));
                }

                replaceScopeImage(image);
            }
        }
    };

    const bulkActions = [
        // The default `value` cannot be ''
        {
            value: BulkOperation.Nothing,
            label: 'Bulk Actions',
        },
        {
            value: BulkOperation.Publish,
            label: 'Publish',
        },
        {
            value: BulkOperation.Draft,
            label: 'Make Private',
        },
        {
            value: BulkOperation.Edit,
            label: 'Edit',
        },
        {
            value: BulkOperation.Delete,
            label: 'Delete',
        },
        ...(fetchCampaigns && addImageToCampaign ?
            [
                {
                    value: BulkOperation.ApplyToCampaign,
                    label: 'Apply to Campaign',
                },
            ]
        :   []),
        ...(removeImageFromCampaign ?
            [
                {
                    value: BulkOperation.RemoveFromCampaign,
                    label: 'Remove from Campaign',
                },
            ]
        :   []),
    ];
    const [bulkAction, setBulkAction] = useState(bulkActions[0].value);
    const longRunningBulkActionPromiseResolver = useRef<Function | undefined>(undefined);
    useEffect(() => {
        const applyBulkAction = async () => {
            if (bulkAction !== BulkOperation.Nothing && selectedImageIds.length === 0) {
                console.warn('No images selected.');
                return;
            }

            switch (bulkAction) {
                case BulkOperation.Nothing:
                    break;
                case BulkOperation.Publish:
                case BulkOperation.Draft:
                    setShowLoading(true);
                    await Promise.all(
                        selectedImages.map(async selectedImage => {
                            return new Promise<void>(async (resolve, reject) => {
                                await status(selectedImage.imageID, bulkAction as 'publish' | 'draft');
                                const image = await load(selectedImage.imageID);
                                replaceScopeImage(image);
                                resolve();
                            });
                        }),
                    );
                    setShowLoading(false);
                    break;
                case BulkOperation.Edit:
                    setIsEditing(true);
                    await new Promise<void>(resolve => {
                        longRunningBulkActionPromiseResolver.current = resolve;
                    });
                    return;
                case BulkOperation.Delete:
                    if (
                        await confirm({
                            title: 'Confirm Deletion',
                            message: 'Are you sure you want to delete these images?',
                            yes: 'Delete',
                            no: 'Cancel',
                            destructive: true,
                        })
                    ) {
                        setShowLoading(true);
                        await Promise.all(
                            selectedImages.map(async selectedImage => {
                                return new Promise<void>(async (resolve, reject) => {
                                    const success = await remove(selectedImage.imageID);
                                    if (success) {
                                        replaceScopeImage(selectedImage);
                                        resolve();
                                    } else {
                                        // TODO: Mention the failure
                                        resolve();
                                    }
                                });
                            }),
                        );

                        if (isSearching) {
                            refreshSearchResults();
                        } else {
                            refreshImages();
                        }
                        setSelectedImageIds([]);
                        setShowLoading(false);
                    }
                    break;
                case BulkOperation.ApplyToCampaign:
                    await new Promise<void>(async resolve => {
                        longRunningBulkActionPromiseResolver.current = resolve;
                        fetchCampaigns?.().then(results => {
                            setCampaigns(
                                results.Results.map(campaign => {
                                    return {
                                        label: campaign.campaignName,
                                        value: campaign.campaignID.toString(),
                                    };
                                }).sort((a, b) => a.label.localeCompare(b.label)),
                            );
                        });
                    });
                    return;
                case BulkOperation.RemoveFromCampaign:
                    if (
                        await confirm({
                            title: 'Remove from Campaign',
                            message: 'Are you sure you want to remove these images from their campaign?',
                            yes: 'Yes, remove them',
                            no: 'Cancel',
                            destructive: true,
                        })
                    ) {
                        setShowLoading(true);
                        await Promise.all(
                            selectedImages.map(async selectedImage => {
                                return new Promise<void>(async (resolve, reject) => {
                                    await removeImageFromCampaign?.(selectedImage.imageID);
                                    resolve();
                                });
                            }),
                        );
                        if (isSearching) {
                            refreshSearchResults();
                        } else {
                            refreshImages();
                        }
                        setShowLoading(false);
                    }
                    break;
                default:
                    console.warn('Unknown bulk action', bulkAction);
                    break;
            }
        };
        applyBulkAction().then(() => {
            console.log('Bulk action complete');
            setBulkAction(bulkActions[0].value);
        });
    }, [bulkAction]);

    const [isLocalLoading, setIsLocalLoading] = useState(false);

    // TODO: Wrap in hook...
    const [isEditing, setIsEditing] = useState(false);
    const [editingTypes, setEditingTypes] = useState<MetaType[]>([
        MetaType.CardType,
        MetaType.Title,
        MetaType.ArtistName,
        MetaType.Link,
        ...(addImageToCampaign || removeImageFromCampaign ? [MetaType.ProductLink] : []),
    ]);

    const [campaigns, setCampaigns] = useState<{ label: string; value: string }[]>([]);
    const [selectedCampaign, setSelectedCampaign] = useState<{ label: string; value: string }>();

    const scopeImages = isSearching ? searchImages?.Results ?? [] : images?.Results ?? [];
    const selectedImages = scopeImages.filter(i => selectedImageIds.includes(i.imageID));

    const totalItems = scopeImages?.length ?? 0;
    const totalResults = isSearching ? searchImages?.Count ?? 0 : images?.Count ?? 0;
    const rangeStart = limit * (page - 1) + 1;
    const rangeEnd = rangeStart + totalItems - 1;

    const paginator = useCallback(() => {
        const usePageCount = isSearching ? searchPageCount : pageCount;

        const paginationUpdated = (
            page: number,
            limit: number,
            sortBy: ImageSortByField,
            sortOrder: ImageSortOrderField,
        ) => {
            setIsLocalLoading(true);
            setSelectedImageIds([]);
            setPage(page);
            setLimit(limit);
            setSortBy(sortBy);
            setSortOrder(sortOrder);
            setSearchParams({
                page: page.toString(),
                limit: limit.toString(),
                sort: sortBy === 'title' ? 'title' : 'date',
                order: sortOrder.toLowerCase(),
            });
            window.scrollTo(0, 0);
        };

        if (!isProcessing) {
            return (
                <Pagination
                    count={usePageCount}
                    page={page}
                    limit={limit}
                    total={totalResults}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    onChange={(event, page) => {
                        paginationUpdated(page, limit, sortBy, sortOrder);
                    }}
                    onLimitChange={limit => {
                        paginationUpdated(1, limit, sortBy, sortOrder);
                    }}
                    onSortChange={(sortBy, sortOrder, newLimit) => {
                        paginationUpdated(1, newLimit || limit, sortBy, sortOrder);
                    }}
                />
            );
        } else {
            return null;
        }
    }, [
        isSearching,
        searchPageCount,
        pageCount,
        isProcessing,
        limit,
        setSearchParams,
        page,
        totalResults,
        sortBy,
        sortOrder,
    ]);

    // const [cropperResultOperation, setCropperResultOperation] = useState<ImageOperation<Upload> | null>();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [lastChecked, setLastChecked] = useState('');
    const isShiftPressed = useRef(false);
    const editorRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Shift') {
                isShiftPressed.current = true;
            }
        };
        const onKeyUp = (event: KeyboardEvent) => {
            if (event.key === 'Shift') {
                isShiftPressed.current = false;
            }
        };
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('keyup', onKeyUp);
        };
    }, []);

    useEffect(() => {
        document.body.style.overflow = isFullscreen ? 'hidden' : '';

        return () => {
            document.body.style.overflow = '';
        };
    }, [isFullscreen]);

    useEffect(() => {
        if (!isEditing) {
            // TODO: Make it less hacky (.5s delay + querying DOM is ugly)
            // Scroll to the last checked checkbox after saving/canceling an edit
            const checkboxes = document.querySelectorAll('.PrivateSwitchBase-input[checked]:not(#selectall)');
            if (checkboxes.length) {
                setTimeout(() => {
                    (checkboxes[checkboxes.length - 1] as HTMLInputElement)
                        ?.closest('.MuiStack-root')
                        ?.scrollIntoView();
                }, 500);
            }
        } else {
            editorRef.current?.scrollIntoView();
        }
    }, [isEditing]);

    const saveChanges = async () => {
        let hasErrors = false;
        const validatedSelected: Image[] = [];
        for (const image of selectedImages) {
            const { results, errors } = validateMeta(image.metaArray);
            if (errors) {
                setMetaErrors(image.imageID, errors);
                hasErrors = true;
            } else if (!hasErrors) {
                validatedSelected.push({
                    ...image,
                    metaArray: results,
                });
            }
        }
        if (hasErrors) {
            await notify('Information missing or invalid', 'Please fill in all required fields');
            return;
        }
        if (
            await confirm({
                title: 'Confirm Save',
                message: 'Are you sure you want to save these changes?',
                yes: 'Yes, save',
                no: 'No, nevermind',
                destructive: true,
            })
        ) {
            setShowLoading(true);
            await Promise.all(
                validatedSelected.map(async selectedImage => {
                    return saveMeta(selectedImage.imageID, selectedImage.metaArray);
                }),
            );

            if (isSearching) {
                refreshSearchResults();
            } else {
                refreshImages();
            }

            setIsEditing(false);
            setShowLoading(false);
            longRunningBulkActionPromiseResolver.current?.();
        }
    };

    return (
        <>
            <Stack
                direction="column"
                spacing={4}
                sx={{
                    alignContent: 'flex-start',
                    px: 2,
                    backgroundColor: darkMode ? '#323241' : Color.White,
                    borderRadius: 2,
                    ...(isEditing && {
                        pb: 2,
                    }),
                    ...(isFullscreen && {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        maxHeight: 'none',
                        borderRadius: 0,
                        overflowY: 'scroll',
                    }),
                }}
                style={{
                    ...(isFullscreen && {
                        marginTop: '0',
                    }),
                }}
            >
                {!isProcessing && (
                    <Stack
                        direction="column"
                        ref={editorRef}
                        spacing={2}
                        sx={{
                            background: 'inherit',
                            ...(isFullscreen && {
                                flexGrow: 1,
                            }),
                        }}
                    >
                        {!isEditing && (
                            <>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    sx={{
                                        alignItems: 'center',
                                        background: 'inherit',
                                        top: 0,
                                        zIndex: 10,
                                        py: 2,
                                        borderBottom: '1px solid',
                                        borderColor: darkMode ? '#555' : '#E5E5E5',
                                        position: 'sticky',
                                    }}
                                >
                                    <Box>
                                        {!isLocalLoading ?
                                            <>
                                                <span>
                                                    {rangeEnd ? `${rangeStart}-${rangeEnd} of` : ''} {totalResults} item
                                                    {totalItems === 1 ? ' ' : 's '}
                                                </span>
                                                {selectedImageIds.length > 0 && (
                                                    <span>
                                                        ({selectedImageIds.length} selected){' '}
                                                        <IconButton
                                                            icon="fa-close"
                                                            onClick={() => setSelectedImageIds([])}
                                                            sx={{
                                                                ml: 0.5,
                                                                fontSize: '.9em',
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                            </>
                                        :   'Loading...'}
                                    </Box>
                                    <Box>
                                        <Select
                                            disabled={selectedImageIds.length === 0}
                                            options={bulkActions}
                                            value={bulkAction}
                                            onChange={value => {
                                                setBulkAction(value as BulkOperation);
                                            }}
                                        />
                                        {search && (
                                            <TextField
                                                value={searchTerm}
                                                placeholder="Filter..."
                                                error={!isValidSearchTerm}
                                                onChange={event => {
                                                    setPage(1);
                                                    setIsValidSearchTerm(validateSearchTerm(event.target.value));
                                                    setSearchTerm(event.target.value);
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            sx={{ backgroundColor: '#F8F8FB' }}
                                                        >
                                                            <i className="fa-solid fa-magnifying-glass"></i>
                                                        </InputAdornment>
                                                    ),
                                                    sx: {
                                                        height: '41px',
                                                        '.MuiInputAdornment-root': {
                                                            background: 'none',
                                                        },
                                                    },
                                                }}
                                                sx={{
                                                    ml: 2,
                                                }}
                                            />
                                        )}
                                        <IconButton
                                            icon={
                                                isFullscreen ?
                                                    'fa-down-left-and-up-right-to-center'
                                                :   'fa-up-right-and-down-left-from-center'
                                            }
                                            onClick={() => setIsFullscreen(!isFullscreen)}
                                            sx={{
                                                ml: 2,
                                            }}
                                        />
                                    </Box>
                                </Stack>
                                <Stack
                                    direction="row"
                                    sx={{
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {!isMobile && (
                                        <label style={{ cursor: 'pointer' }}>
                                            <Checkbox
                                                checked={((): boolean => {
                                                    return selectedImageIds.length === scopeImages.length;
                                                })()}
                                                onChange={event => {
                                                    if (event.target.checked) {
                                                        setSelectedImageIds(scopeImages.map(i => i.imageID));
                                                    } else {
                                                        setSelectedImageIds([]);
                                                    }
                                                }}
                                                id="selectall"
                                                sx={{ mr: 2 }}
                                            />
                                            <span style={{ verticalAlign: 'middle' }}>Select All</span>
                                        </label>
                                    )}
                                    {fetchCampaigns && !isSearching && (
                                        <label style={{ cursor: 'pointer' }}>
                                            <Checkbox
                                                checked={hideCampaigns}
                                                onChange={event => {
                                                    setHideCampaigns(event.target.checked);
                                                    setPage(1);
                                                }}
                                                sx={{ mr: 1 }}
                                            />
                                            <span style={{ verticalAlign: 'middle' }}>Hide Campaign IRCODES</span>
                                        </label>
                                    )}
                                </Stack>
                            </>
                        )}

                        {isEditing && (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                sx={{
                                    flexGrow: 1,
                                    alignItems: 'center',
                                    position: 'sticky',
                                    top: 0,
                                    background: 'inherit',
                                    py: 2,
                                    zIndex: 10,
                                }}
                            >
                                <Select
                                    multiple
                                    value={editingTypes.map(type => type)}
                                    renderValue={selected => 'Form Fields'}
                                    options={names.map(name => {
                                        return {
                                            value: name,
                                            label: camelCaseToWords(name),
                                        };
                                    })}
                                    onChange={value => {
                                        setEditingTypes(value as MetaType[]);
                                    }}
                                />
                                <Stack direction="row" spacing={2}>
                                    <Button disabled={hasMetaErrors} variant="contained" onClick={saveChanges}>
                                        Save Changes
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={async () => {
                                            if (
                                                await confirm({
                                                    title: 'Confirm Cancel',
                                                    message: 'Are you sure you want to cancel these changes?',
                                                    yes: 'Yes, cancel',
                                                    no: 'No, wait',
                                                    destructive: true,
                                                })
                                            ) {
                                                setShowLoading(true);
                                                await Promise.all(
                                                    selectedImages.map(async selectedImage => {
                                                        const image = await load(selectedImage.imageID);
                                                        replaceScopeImage(image);
                                                    }),
                                                );
                                                setIsEditing(false);
                                                setShowLoading(false);
                                                longRunningBulkActionPromiseResolver.current?.();
                                            }
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <IconButton
                                        icon={
                                            isFullscreen ?
                                                'fa-down-left-and-up-right-to-center'
                                            :   'fa-up-right-and-down-left-from-center'
                                        }
                                        onClick={() => setIsFullscreen(!isFullscreen)}
                                        sx={{
                                            ml: 2,
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        )}

                        <Stack
                            direction="column"
                            spacing={2}
                            sx={{
                                flexGrow: 1,
                                minHeight: 'calc(100vh - 30em)',
                            }}
                        >
                            {isLocalLoading && (
                                <CircularProgress
                                    sx={{
                                        alignSelf: 'center',
                                        '~ div': {
                                            display: 'none',
                                        },
                                    }}
                                    style={{
                                        margin: 'auto',
                                    }}
                                />
                            )}

                            {isEditing ?
                                selectedImages.map((image, i) => {
                                    const onChange = (metaType: MetaType, metaContent: MetaContent) => {
                                        const newImage = structuredClone(image);
                                        const index = indexForMetaType(image.metaArray, metaType);
                                        if (index !== -1) {
                                            newImage.metaArray[index].metaContent = metaContent;
                                        } else {
                                            newImage.metaArray.push(newMetaField(metaType, metaContent));
                                        }

                                        replaceScopeImage(newImage);
                                    };

                                    return (
                                        <OwnedIrcodeRow
                                            key={`${i}-${image.imageID}`}
                                            image={image}
                                            isEditing={isEditing}
                                            editingTypes={editingTypes}
                                            copyOnConfirm={copyOnConfirm}
                                            onChange={onChange}
                                            onEdit={() => onEdit(image.imageID)}
                                            onReplace={(image, metaType) => {}}
                                            onCopy={(image, metaType) => {
                                                copyImageMetaData(image, selectedImages, replaceScopeImage, metaType);
                                            }}
                                            validationId={image.imageID}
                                            // onCrop={async () => setCropperResultOperation(await imageOperationFromImage(image))}
                                        />
                                    );
                                })
                            :   scopeImages.map((image, i) => {
                                    return (
                                        <OwnedIrcodeRow
                                            key={`${i}-${image.imageID}`}
                                            image={image}
                                            showCheckbox={true}
                                            isSelected={selectedImageIds.includes(image.imageID)}
                                            onSelected={() => {
                                                let newSelectedImageIds = structuredClone(selectedImageIds);
                                                addOrRemove(newSelectedImageIds, image.imageID);
                                                // if this image is being checked
                                                if (newSelectedImageIds.includes(image.imageID)) {
                                                    // and we have a last checked image and we're holding shift
                                                    if (lastChecked && isShiftPressed.current) {
                                                        const lastCheckedIndex = scopeImages.findIndex(
                                                            i => i.imageID === lastChecked,
                                                        );
                                                        const currentCheckedIndex = scopeImages.findIndex(
                                                            i => i.imageID === image.imageID,
                                                        );
                                                        if (lastCheckedIndex !== -1 && currentCheckedIndex !== -1) {
                                                            const start = Math.min(
                                                                lastCheckedIndex,
                                                                currentCheckedIndex,
                                                            );
                                                            const end = Math.max(lastCheckedIndex, currentCheckedIndex);
                                                            newSelectedImageIds = Array.from(
                                                                new Set([
                                                                    ...newSelectedImageIds,
                                                                    ...scopeImages
                                                                        .slice(start, end + 1)
                                                                        .map(i => i.imageID),
                                                                ]),
                                                            );
                                                        }
                                                    }
                                                    setLastChecked(image.imageID);
                                                } else {
                                                    setLastChecked('');
                                                }
                                                setSelectedImageIds(newSelectedImageIds);
                                            }}
                                            onEdit={() => onEdit(image.imageID)}
                                        />
                                    );
                                })
                            }
                        </Stack>
                        {!isEditing && (
                            <Box
                                sx={{
                                    position: 'sticky',
                                    bottom: 0,
                                    zIndex: 10,
                                    backgroundColor: 'inherit',
                                    borderTop: '1px solid',
                                    borderColor: darkMode ? '#555' : '#E5E5E5',
                                    py: 1,
                                }}
                            >
                                {paginator()}
                            </Box>
                        )}
                    </Stack>
                )}
            </Stack>
            <Dialog
                open={bulkAction === BulkOperation.ApplyToCampaign}
                sx={{
                    '& .MuiDialog-paper': {
                        p: 4,
                        borderRadius: 2,
                    },
                }}
            >
                <Stack direction="column" spacing={2}>
                    <Typography
                        sx={{
                            fontFamily: 'Nocturne Serif',
                            fontSize: '32px',
                            fontWeight: 600,
                            lineHeight: '40px',
                            letterSpacing: '-0.03em',
                            textAlign: 'left',
                        }}
                    >
                        Select Campaign
                    </Typography>
                    <Select
                        options={campaigns}
                        value={selectedCampaign?.value ?? ''}
                        onChange={value => {
                            setSelectedCampaign(campaigns.find(c => c.value === value));
                        }}
                    />
                    <Button
                        variant="irdbGradient"
                        sx={{
                            height: 56,
                        }}
                        onClick={async () => {
                            if (selectedCampaign === undefined) {
                                return;
                            }

                            setShowLoading(true);
                            await Promise.all(
                                selectedImages.map(async selectedImage => {
                                    return addImageToCampaign?.(
                                        parseInt(selectedCampaign.value),
                                        selectedImage.imageID,
                                    );
                                }),
                            );
                            setShowLoading(false);
                            setSelectedCampaign(undefined);
                            longRunningBulkActionPromiseResolver.current?.();
                        }}
                    >
                        Use Campaign
                    </Button>
                    <Button
                        variant="irdbGray"
                        sx={{
                            height: 56,
                        }}
                        onClick={() => {
                            setSelectedCampaign(undefined);
                            longRunningBulkActionPromiseResolver.current?.();
                        }}
                    >
                        Cancel
                    </Button>
                </Stack>
            </Dialog>
            {/* <Dialog open={!!cropperResultOperation}>
                <Cropper
                    imageOperation={cropperResultOperation}
                    onSuccess={i => {
                        const image = i.operation.Results?.Image;
                        if (image) {
                            replaceScopeImage(image);
                        }
                        setCropperResultOperation(null);
                    }}
                    onProgress={() => {}}
                    onRetake={() => setCropperResultOperation(null)}
                    onCancel={() => setCropperResultOperation(null)}
                />
            </Dialog> */}
        </>
    );
}
