import { MetaType } from '../../types/MetaTypes';
import { create } from 'zustand';
import { MetaErrors } from '../../types/MetaValidation';
import { createSelectors } from '../../util/zustand';

type MetaErrorMap = Record<string, MetaErrors>;

type SetErrors = {
    (id: string, errors: MetaErrors): void;
    (errors: MetaErrorMap): void;
};

export interface MetaErrorsStore {
    /** List of ids that have an error in them */
    hasErrors: string[];
    errors: MetaErrorMap;
    setErrors: SetErrors;
    setError: <T extends MetaType>(id: string, metaType: T, error: MetaErrors[T]) => void;
    removeError: <T extends MetaType>(id: string, metaType: T) => void;
    clearErrors: (id?: string) => void;
}

const getHasErrors = (errors: MetaErrors) => {
    return Object.keys(errors).some(key => {
        const value = errors[key as MetaType];
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        return !!value;
    });
};

const getMergedHasErrors = (state: MetaErrorsStore, id: string, errors: MetaErrors | null | undefined) => {
    if (!errors) {
        return state.hasErrors.filter(errorId => errorId !== id);
    }
    const hasErrorsIndex = state.hasErrors.indexOf(id);
    const hasErrors = getHasErrors(errors);
    if (hasErrors) {
        if (hasErrorsIndex === -1) {
            return [...state.hasErrors, id];
        }
        return state.hasErrors;
    }
    if (hasErrorsIndex !== -1) {
        return state.hasErrors.filter(errorId => errorId !== id);
    }
    return state.hasErrors;
};

const useMetaErrors = createSelectors(
    create<MetaErrorsStore>(set => ({
        hasErrors: [],
        errors: {},
        setErrors: (idOrErrorMap: string | MetaErrorMap, errors?: MetaErrors) => {
            if (typeof idOrErrorMap === 'string') {
                set(state => {
                    return {
                        errors: {
                            ...state.errors,
                            [idOrErrorMap]: errors || {},
                        },
                        hasErrors: getMergedHasErrors(state, idOrErrorMap, errors),
                    };
                });
                return;
            }
            set({ errors: idOrErrorMap, hasErrors: Object.keys(idOrErrorMap) });
        },
        setError: <T extends MetaType>(id: string, metaType: T, error: MetaErrors[T]) =>
            set(state => {
                const merged = {
                    ...state.errors[id],
                    [metaType]: error,
                };
                return {
                    errors: {
                        ...state.errors,
                        [id]: merged,
                    },
                    hasErrors: getMergedHasErrors(state, id, merged),
                };
            }),
        removeError: <T extends MetaType>(id: string, metaType: T) => {
            set(state => {
                const errors = structuredClone(state.errors);
                const metaErrors = errors[id];
                if (metaErrors) {
                    delete metaErrors[metaType];
                    if (Object.keys(metaErrors).length === 0) {
                        delete errors[id];
                    }
                }
                return { errors, hasErrors: getMergedHasErrors(state, id, metaErrors) };
            });
        },
        clearErrors: (id?: string) => {
            if (id) {
                set(state => {
                    const errors = { ...state.errors };
                    delete errors[id];
                    return { errors, hasErrors: getMergedHasErrors(state, id, null) };
                });
                return;
            }
            set({ errors: {}, hasErrors: [] });
        },
    })),
);

useMetaErrors.subscribe(state => console.log(state));

export default useMetaErrors;
