import { useEffect, useRef, useState } from 'react';

const useExpiringState = <T>(expiry: number, defaultValue: T | undefined) => {
    const [value, setValue] = useState(defaultValue);
    const handle = useRef<number | null>();

    useEffect(
        () => () => {
            if (handle.current) {
                window.clearTimeout(handle.current);
            }
        },
        [],
    );

    const set = (v: T | undefined) => {
        setValue(v);

        if (handle.current) {
            window.clearTimeout(handle.current);
            handle.current = null;
        }

        handle.current = window.setTimeout(() => {
            setValue(undefined);
            handle.current = null;
        }, expiry);
    };

    return [value, set] as const;
};

export default useExpiringState;
