import { Box, IconButton, Stack, SwipeableDrawer } from '@mui/material';
import { useContext, useRef, useState, SyntheticEvent } from 'react';
import { ImageOperation, Query, Image } from '../../contexts/MediaContext';
import Drawer from '../general/Drawer';
import Info from './Info';
import SimilarImages from './SimilarImages';
import { Color } from '../../Color';
import { ZIndex } from '../../App';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';

interface Props {
    imageOperation?: ImageOperation<Query>;
    onCancel: () => void;
}

export default function Available({ imageOperation: initialImageOperation, onCancel }: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    // const { notify, setShowLoading } = useContext(FeedbackContext) as TFeedback;
    // const { upload, add } = useContext(MediaContext) as TMedia;

    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageOperation, setImageOperation] = useState(initialImageOperation);
    const [selectedImage, setSelectedImage] = useState<Image>();
    const [now, setNow] = useState(Date.now());

    const ref = useRef(null);

    if (!imageOperation) {
        return null;
    }

    const similarImages = imageOperation.operation?.Results?.Images || [];

    return (
        <Stack
            ref={ref}
            sx={{
                height: '100%',
            }}
        >
            <IconButton
                onClick={() => onCancel()}
                sx={{
                    fontSize: '2em',
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    zIndex: 3,
                }}
            >
                <i className="fa-solid fa-circle-xmark" style={{ color: 'white' }} />
            </IconButton>
            <Box
                sx={{
                    backgroundColor: Color.PrimaryDarkGrayBlue,
                    flex: 1,
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        zIndex: 1,
                    }}
                >
                    <img
                        src={imageOperation.cropped?.preview ?? imageOperation.original?.preview}
                        style={{
                            objectFit: 'cover',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            filter: 'blur(.7em) brightness(.8)',
                            zIndex: 1,
                        }}
                        alt=""
                    />
                    <img
                        src={imageOperation.cropped?.preview ?? imageOperation.original?.preview}
                        style={{
                            objectFit: 'contain',
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            zIndex: 2,
                        }}
                        onLoad={() => {
                            // console.log("loaded");
                            // URL.revokeObjectURL(image.preview)
                            setImageLoaded(true);
                            setNow(Date.now());
                        }}
                        alt=""
                    />
                </div>
                <Stack
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        zIndex: 2,
                        p: 3,
                    }}
                >
                    NOT FOUND
                </Stack>
            </Box>

            {/* Similar Images */}
            <Box
                sx={{
                    backgroundColor: darkMode ? Color.PrimaryDarkGrayBlue : Color.White,
                    pt: 2,
                    zIndex: 3,
                }}
            >
                <SimilarImages
                    isSimilarImagesLoading={false}
                    similarImages={similarImages}
                    setImage={setSelectedImage}
                />
            </Box>

            {/* Image Info */}
            <SwipeableDrawer
                anchor="bottom"
                open={selectedImage !== undefined}
                disableSwipeToOpen={true}
                ModalProps={{
                    keepMounted: false,
                }}
                onClose={function (event: SyntheticEvent<{}, Event>): void {
                    setSelectedImage(undefined);
                }}
                onOpen={function (event: SyntheticEvent<{}, Event>): void {}}
                style={{
                    zIndex: ZIndex.ProfileSheet,
                }}
            >
                <Drawer>
                    <Info image={selectedImage} onClose={() => setSelectedImage(undefined)} />
                </Drawer>
            </SwipeableDrawer>
        </Stack>
    );
}
