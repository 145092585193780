import { useStore as useZustandStore, StoreApi, UseBoundStore } from 'zustand';

/** Infers the state type from StoreApi */
type ExtractState<S> =
    S extends (
        {
            getState: () => infer T;
        }
    ) ?
        T
    :   never;
/** Zustand store selector */
export type Selector<T extends StoreApi<unknown>, R> = (store: ExtractState<T>) => R;

/** Strips the imperative access methods from StoreApi */
export type StoreHook<T extends StoreApi<object>> = {
    (selector?: never): ExtractState<T>;
    <R>(selector?: Selector<T, R>): R;
};

/** Abstracts the zustand store api to a hook that accepts selectors and can be used in components */
function createStoreHook<T extends StoreApi<object>>(store: T): StoreHook<T> {
    return <R = unknown>(selector?: Selector<T, R>) => {
        return useZustandStore(store, selector!);
    };
}

/** Object with auto selectors */
type SelectorsObject<T> = { use: { [K in keyof Required<T>]: () => T[K] } };
/** Store with auto selectors */
type WithSelectors<S> = S extends { getState: () => infer T } ? S & SelectorsObject<T> : never;
/** Store with auto selectors in external object */
type WithSelectorTarget<S extends UseBoundStore<StoreApi<object>>, T> = T &
    (S extends { getState(): infer K } ? SelectorsObject<K> : never);

// Note: creating auto selectors removes the need to create and memoize selectors in components
/** Creates auto selectors for a store and assigns them to the store object */
function createSelectors<S extends UseBoundStore<StoreApi<object>>>(store: S): WithSelectors<S>;
/** Creates auto selectors for a store and assigns them to the target object */
function createSelectors<S extends UseBoundStore<StoreApi<object>>, T>(store: S, target: T): WithSelectorTarget<S, T>;
function createSelectors<S extends UseBoundStore<StoreApi<object>>, T>(
    _store: S,
    target?: T,
): WithSelectors<S> | WithSelectorTarget<S, T> {
    let store: WithSelectors<S> | WithSelectorTarget<S, T>;
    if (target) {
        store = target as WithSelectorTarget<S, T>;
    } else {
        store = _store as WithSelectors<S>;
    }
    store.use = {};
    for (const k of Object.keys(_store.getState())) {
        (store.use as any)[k] = () => _store(s => s[k as keyof typeof s]);
    }

    return store;
}

export { createStoreHook, createSelectors };
