import { Box, Chip, Divider, Link as MuiLink, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { Color } from '../../Color';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { isTProductLink, TProductLink } from '../../types/ProductLink';
import { nullUndefinedOrEmpty } from '../../util/string';
import { MetaFieldProps } from './Props';
import productStores from 'src/constants/productStores.json'; // TODO: get this from Firebase like iOS app does

const ProductLink = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TProductLink | undefined;

    if (!content) {
        return null;
    }

    const getStoreImage = (url: string) => {
        const domain = new URL(url).hostname;
        const store = productStores.find(store =>
            // check if the product's hostname matches any known store domains, avoiding partial mismatches or subdomains/www
            store.domains.some(domainRegex => new RegExp(`(^|\\.)${domainRegex}`).test(domain)),
        );
        return (
            store ?
                darkMode ? store.buyBtnDark
                :   store.buyBtnLight
            :   ''
        );
    };

    return (
        <Stack
            spacing={1}
            sx={{
                mt: 2,
                px: 3,
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Nunito Sans',
                    fontWeight: 400,
                    fontSize: '1.2em',
                }}
            >
                Products
            </Typography>
            <Divider />
            {(isTProductLink(content) ? content.links : []).map(link => {
                let url: URL | undefined;
                try {
                    if (!nullUndefinedOrEmpty(link.linkToFollow) && !/^https?:\/\//i.test(link.linkToFollow)) {
                        link.linkToFollow = 'https://' + link.linkToFollow;
                    }

                    url = new URL(link.linkToFollow);
                } catch (error) {
                    // console.error('Error creating URL:', error);
                }

                if (!url) {
                    return null;
                }

                return (
                    <Box key={url.href}>
                        <MuiLink
                            href={url.href}
                            target="_blank"
                            rel="noreferrer noopener"
                            sx={{
                                textDecoration: 'none',
                            }}
                        >
                            <Chip
                                sx={{
                                    fontFamily: 'Nunito Sans',
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: darkMode ? Color.White : Color.Black,
                                    backgroundColor:
                                        darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                                    borderRadius: '.4em',
                                    border: '1px solid rgba(0, 0, 0, 0.05)',
                                    display: 'block',
                                    overflow: 'hidden',
                                    width: '100%',
                                    height: 'auto',
                                    ...{
                                        '.MuiChip-label': {
                                            display: 'block',
                                            px: 0,
                                            pr: 2,
                                        },
                                    },
                                }}
                                label={
                                    <Stack direction="row" sx={{ alignItems: 'center', height: 80 }}>
                                        {link.imageUrl && (
                                            <img
                                                src={link.imageUrl}
                                                alt={link.title}
                                                style={{
                                                    background: 'white',
                                                    width: 80,
                                                    height: 80,
                                                    objectFit: 'contain',
                                                    flexShrink: 0,
                                                }}
                                            />
                                        )}
                                        <span style={{ flexGrow: 1, padding: '0 1em', whiteSpace: 'normal' }}>
                                            {!nullUndefinedOrEmpty(link.title) ? link.title : url.hostname}
                                        </span>
                                        <div
                                            style={{
                                                flexShrink: 0,
                                                width: 90,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {getStoreImage(link.linkToFollow) ?
                                                <img
                                                    src={getStoreImage(link.linkToFollow)}
                                                    alt={link.linkToFollow}
                                                    style={{ width: '100%', height: 50, objectFit: 'contain' }}
                                                />
                                            :   <>
                                                    Buy on
                                                    <br />
                                                    {new URL(link.linkToFollow).hostname.replace('www.', '')}
                                                </>
                                            }
                                        </div>
                                    </Stack>
                                }
                            />
                        </MuiLink>
                    </Box>
                );
            })}
        </Stack>
    );
};

export { ProductLink };
